const oidcConfig = {
  client_id: "myaccount.cyberid.vn",
  redirect_uri: "https://profile.xcyber.vn/authentication/callback",
  response_type: "code",
  post_logout_redirect_uri: "https://profile.xcyber.vn/",
  scope: "openid profile email roles",
  authority: "https://accounts.xcyber.vn/auth/realms/cyberid",
  silent_redirect_uri: "https://profile.xcyber.vn/authentication/silent_callback",
  automaticSilentRenew: true,
  loadUserInfo: true
}

export default oidcConfig
